
.scrap-local-form {

  width: 100%;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;

  .form-fields {

    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    &.vertical-layout {
      max-width: 440px;
      flex-direction: column;
    }

    &.horizontal-layout {
      max-width: 1280px;
      flex-direction: row;

      @media screen and (max-width: 960px) {
        max-width: 440px;
        margin: 0 auto 10px auto;
        flex-direction: column;
      }
    }

  }

  .form-controls {

    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 100%;
    }

    &.horizontal-layout {
      button {
        max-width: 320px;

        @media screen and (max-width: 960px) {
          max-width: 440px;
        }
      }
    }
  }

  .form-field-wrapper {

    width: 100%;

    .field-error {
      display: none;
      font-size: .875rem;
      line-height: 1.25rem;
      color: rgba(204, 106, 76, 1);
      background-color: rgba(255, 247, 248, 1);
      border-radius: 0.375rem;
      text-align: left;
      padding: 8px;
      margin-top: 5px;
    }

    input, select {
      text-transform: uppercase;
      font-weight: 700;
      width: 100%;
      height: 70px;
      padding: 0 20px;
      font-size: 1.25rem;
      line-height: 1.75rem;
      letter-spacing: .1em;
      border: 1px solid rgba(229, 231, 235, 1);
      border-radius: 0 8px 8px 0;
      color: black;
      outline: none;

      &::placeholder {
        text-transform: uppercase;
        font-weight: 700;
        color: rgba(145, 145, 145, 1);
      }

      &.email-field-input {
        text-transform: lowercase;
      }

      &.waste-field-input {
        color: rgba(145, 145, 145, 1);
      }



    }

    .field-with-icon {
      display: flex;
      width: 100%;
      flex-direction: row;

      .field-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
        width: 70px;
        height: 70px;
        border-radius: 8px 0 0 8px;

        &.waste-field-icon-wrapper {
          background-color: rgba(37, 152, 235, 1);
        }

        &.vrm-field-icon-wrapper {
          background-color: rgba(37, 99, 235, 1);
        }

        &.postcode-field-icon-wrapper {
          background-color: rgba(220, 38, 38, 1);
        }

        &.phone-field-icon-wrapper {
          background-color: rgba(145, 145, 145, 1);
        }

        &.email-field-icon-wrapper {
          background-color: rgba(145, 145, 145, 1);
        }
      }

      .field-input-wrapper {
        flex-grow: 1;
      }
    }

    &.vrm-field-wrapper {

      input {
        background-color: rgba(254, 213, 54, 1);
        color: black;
      }

      input::placeholder {
        color: rgba(0, 0, 0, 1);
      }

    }

  }

  button[type=submit] {
    color: white;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1rem;
    letter-spacing: .05rem;
    cursor: pointer;
    padding: 1.25rem 0;
    border-radius: 9999px;
    background-color: rgba(17, 168, 135, 1);
    border: none;
    outline: none;
  }
}


